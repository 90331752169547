<template>
  <div class="">
    <div class="uk-box-shadow-medium uk-background-default uk-padding-small brd12">
      <form @submit.prevent="createUser">
        <div class="uk-grid uk-grid-small uk-margin-small">
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="tel"
            placeholder="Логин"
            v-model="user.login"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="text"
            placeholder="Пароль"
            v-model="user.password"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="email"
            placeholder="E-mail"
            v-model="user.email"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="text"
            placeholder="Фамилия"
            v-model="user.surname"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="text"
            v-model="user.first_name"
            placeholder="Имя"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <input
            class="uk-input"
            type="text"
            v-model="user.last_name"
            placeholder="Отчество"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <div class="fnt-bld">Дата рождения:</div>
            <input
            class="uk-input"
            type="date"
            v-model="user.birthday"
            placeholder="Дата рождения"
            >
          </div>
          <div class="uk-text-left uk-margin-small-bottom">
            <div class="fnt-bld">Пол:</div>
             <label>
               <input
               type="radio"
               :value="0"
               v-model="user.sex"
               placeholder="Дата рождения"
               >
               <span>Муж.</span>
             </label>
             <label>
               <input
               type="radio"
               :value="1"
               v-model="user.sex"
               placeholder="Дата рождения"
               >
               <span>Жен.</span>
             </label>
          </div>
        </div>

        <button class="btn uk-margin-medium-top bg-church brd36 cl-wh show-animation fs16 uk-box-shadow" @click="addVideo">
          Добавить
        </button>
      </form>

    </div>

    <div class="uk-box-shadow-medium uk-background-default uk-padding-small uk-margin-top brd12">
      <table class="uk-table uk-table-divider uk-table-hover uk-table-small uk-table-middle">
          <caption></caption>
          <thead>
              <tr class="" style="border:none">
                <th class="">Фото</th>
                <th class="">Имя
                    <span
                      class="material-icons cr-pointer show-animation show-animation:hover cl-vevanta uk-text-middle"
                      >arrow_drop_down
                    </span>
                  </th>
                <th class="">Телефон</th>
                <th class="">E-mail</th>
                <th class="">Пол</th>
                <th class="">роль</th>
                <th class="">Статус
                  <span
                    class="material-icons cr-pointer show-animation show-animation:hover cl-vevanta uk-text-middle"
                    >arrow_drop_down
                  </span>

                </th>
                <th class="">Операции</th>
              </tr>
          </thead>
          <tbody>
              <tr class="uk-width-medium uk-text-left" v-for="(user, index) in users" :key="index">
              <td class=""><img class="avatar_nav cr-pointer uk-border-circle" :src='user.photo'></td>
              <td class="">{{`${user.surname} ${user.first_name}`}}</td>
              <td class="">{{user.login}}</td>
              <td class="">{{user.email}}</td>
              <td class="">
                <template v-if="user.sex === 0">
                  Брат
                </template>
                <template v-else>
                  Сестра
                </template>
              </td>
              <td class="">
                <template v-if="user.role=== 0">
                  Пользователь
                </template>
                <template v-else>
                  Администратор
                </template>
              </td>
              <td>

                <div v-if="user.status === 1" class="uk-margin-left status__active uk-border-pill" style="width: 15px; height: 15px">
                </div>
                <div v-else-if="user.status === 2" class="uk-margin-left status__warning uk-border-pill" style="width: 15px; height: 15px">
                </div>
                <div v-else-if="user.status === 0" class="uk-margin-left status__del uk-border-pill" style="width: 15px; height: 15px">
                </div>
              </td>
              <td class="uk-flex">
                <div class='uk-border-circle circle-l bg-grey uk-flex' @click="editUser(index)">
                  <span
                    class="m-auto material-icons cl-blue cr-pointer"
                    >
                    edit
                  </span>
               </div>
               <div class='uk-margin-small-left uk-border-circle circle-l bg-grey uk-flex' @click="editPswUser(index)">
                 <span
                   class="m-auto material-icons cl-blue cr-pointer"
                   >
                   key
                 </span>
              </div>
              </td>
              </tr>
          </tbody>
      </table>
    </div>

    <div id="modal-lead-edit" :class="{'active': editUserModal}" class="uk-position-fixed uk-position-top uk-height-1-1 uk-width-1-1 z-index-1000 edit-lead-modal" style="background: rgb(0 0 0 / 40%)" @click="closeModalUserEdit($event)">
  <div class="uk-position-fixed uk-position-right uk-background-default z-index-100 uk-padding-small edit-lead">
        <div class="uk-width-medium">
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Телефон</div>
            <input class="uk-input" type="text" name="" v-model="editUserInfo.login">
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Имя</div>
            <input class="uk-input" type="text" name="" v-model="editUserInfo.first_name">
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Фамилия</div>
            <input class="uk-input" type="text" name="" v-model="editUserInfo.surname">
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Отчество</div>
            <input class="uk-input" type="text" name="" v-model="editUserInfo.last_name">
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">E-mail</div>
            <input class="uk-input" type="text" name="" v-model="editUserInfo.email">
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Активность</div>
            <select
              class="uk-select"
              v-model="editUserInfo.status"
              >
                <option value="1">Активный</option>
                <option value="0">В архив</option>
                <option value="2">Отлучен</option>
            </select>
          </div>
          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Роль</div>
            <select
              class="uk-select"
              v-model="editUserInfo.role"
              >
              <option value="0">Пользователь</option>
              <option value="1">Администратор</option>

            </select>
          </div>
          <div class="btn bg-yellow brd36 fnt-med cl-wh uk-text-left" @click="saveUser">
            <div class="uk-grid uk-grid-small uk-flex-middle">
              <div class="uk-flex">
                <span class="material-icons">update</span>
              </div>
              <div>
                <span>Сохранить</span>
              </div>
            </div>
          </div>

          <hr>

          <div class="uk-margin-small-bottom">
            <div class="fnt-med uk-text-left">Новый пароль</div>
            <input class="uk-input" type="text" name="" v-model="userInfoPsw.psw">
          </div>

          <div class="btn bg-yellow brd36 fnt-med cl-wh uk-text-left" @click="saveUserPsw">
            <div class="uk-grid uk-grid-small uk-flex-middle">
              <div>
                <span>Сохранить пароль</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UIkit from 'uikit'

  export default {
    data() {
      return {
        editUserModal: 0,
        editUserModalPsw: 0,
        editUserInfo: {
          login: '',
          first_name: '',
          surname: '',
          last_name: '',
          email: '',
          status: 1
        },
        user: {
          email: '',
          login: '',
          surname: '',
          first_name: '',
          last_name: '',
          birthday: '',
          password: '',
          role: 0,
          sex: 0,
        },
        userInfoPsw: {
          psw: '',
        },
        users: [],
        indexEditUser: '',

      }
    },
    created() {
      this.getUsers()
    },
    methods: {
      editUser(index) {
        this.editUserModal = 1
        this.indexEditUser = index

      //  this.editUserInfo.login = this.users[index].login
        this.editUserInfo.email = this.users[index].email
        this.editUserInfo.surname = this.users[index].surname
        this.editUserInfo.first_name = this.users[index].first_name
        this.editUserInfo.last_name = this.users[index].last_name
        this.editUserInfo.status = this.users[index].status
        this.editUserInfo.id = this.users[index].id
        this.editUserInfo.role = this.users[index].role

        console.log(this.editUserInfo)
      },

      saveUser() {
        this.$api.post('admin/users/update_info', this.editUserInfo)
        .then(rs => {
          UIkit.notification({
            message: 'Пользователь успешно обновлён',
            status: 'success',
            pos: 'top-right',
          });

          this.users[this.indexEditUser] = rs.data.user
        })
      },

      saveUserPsw() {
        this.$api.post('admin/users/update_info/psw', {
          id: this.editUserInfo.id,
          psw: this.userInfoPsw.psw
        })
        .then(rs => {
          UIkit.notification({
            message: rs.data.msg,
            status: 'success',
            pos: 'top-right',
          });

          this.users[this.indexEditUser] = rs.data.user
        })
      },

      closeModalUserEdit(e) {
        if (e.target.id === 'modal-lead-edit') {
          this.editUserModal = 0
        }
      },
      getUsers() {
        this.users = []
        this.statusLoadUsers = 1
        this.$api.post('/admin/users/get_users', {

        }).then(rs => {
          if (rs.data.data.length) {
            this.statusLoadUsers = 0
            this.users = rs.data.data
          } else {
            this.statusLoadUsers = 2
          }
          console.log(rs)
        })
      },
      createUser() {
        if (!this.user.login) {
          UIkit.notification({
            message: 'Введите логин',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

        if (!this.user.password) {
          UIkit.notification({
            message: 'Выберите пароль',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }


        if (!this.user.surname) {
          UIkit.notification({
            message: 'Введите фамилию',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

        if (!this.user.first_name) {
          UIkit.notification({
            message: 'Введите имя',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }

    /*    if (!this.user.last_name) {
          UIkit.notification({
            message: 'Введите отчество',
            status: 'danger',
            pos: 'top-right',
          });
          return
        } */

        if (!this.user.birthday) {
          UIkit.notification({
            message: 'Выберите дату рождения',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }



        if (this.user.sex !== 0 && this.user.sex !== 1) {
          UIkit.notification({
            message: 'Выберите пол',
            status: 'danger',
            pos: 'top-right',
          });
          return
        }


        this.$api.post('https://api.mission-hve.ru/register', {
          ...this.user
        })
        .then(rs => {
          UIkit.notification({
            message: 'Пользователь успешно создан',
            status: 'success',
            pos: 'top-right',
          });
          this.getUsers()
        }).catch(rs => {
          UIkit.notification({
            message: 'Ошибка. Проверьте данные',
            status: 'danger',
            pos: 'top-right',
          });
        })
      }
    }
}
</script>


<style>

.edit-lead-modal {
  visibility: hidden;
}

.edit-lead-modal.active {
  visibility: visible;
}

.edit-lead-modal.active .edit-lead {
  transform: translateX(0%);
}
.edit-lead {
  transition: 0.5s;
  transform: translateX(100%);
}

.avatar_nav {
  height: 35px;
  width: 35px;
  background: #f5f5f5
}

.status__active {
  background-color: #97c535;
}

.status__warning {
  background-color: #dfcb25;
}

.status__del {
  background-color: #cdcdcd;
}
</style>
